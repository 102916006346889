<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-title class="primary white--text" dark>
          <v-icon color="white">mdi-truck-delivery</v-icon>
          <span class="title ml-2">Outbound Under 25T Loads Report</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-6">
              <v-col cols="12" xs="12" md="12">
                <v-text-field
                  v-model="fromDate"
                  placeholder="Date From"
                  label="Date From"
                  type="date"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="toDate"
                  placeholder="Date To"
                  label="Date To"
                  type="date"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" md="12">
                    <h4>Minimum Outbound Weight:</h4>
                    <v-text-field
                      v-model="minOutWeight"
                      outlined
                      type="number"
                      suffix="Tonnes"
                    ></v-text-field>
                  </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-btn to="/">Close</v-btn>&nbsp;
            <v-btn @click="runReport" class="primary">Excel</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import downloadjs from "downloadjs";
import dayjs from "dayjs";

export default {
  async mounted() {
    this.initDates();
    await this.getGlobalSettingForMinWeight();
  },

  data: () => ({
    fromDate: null,
    toDate: null,
    minOutWeight: null,
  }),

  methods: {
    async getGlobalSettingForMinWeight() {
      this.$root.showProcessing();

      try {
        let response = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + `global-settings/getSetting/MINIMUM_OUTBOUND_LOAD`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            method: "GET",
          }
        );

        if (response && response.ok) {
          let results = await response.json();
          console.log("getGlobalSettingForMinWeight : results : ", results);
          this.minOutWeight = results.data.value;
        } else {
          throw new Error(`${response.status} ${response.statusText}`);
        }

      } catch (error) {
        console.log("getGlobalSettingForMinWeight : Error : ", error);
      } finally {
        this.$root.hideProcessing();
      }
    },

    async runReport() {
      this.$root.showProcessing();

      try {
        const response = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            `reporting/outboundUnder25TLoads`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
              params: {
                fromDate: this.fromDate,
                toDate: this.toDate,
                minOutWeight: this.minOutWeight,
              },
            }),
          }
        );

        const blob = await response.blob();
        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`);
        }

        downloadjs(
          blob,
          "outboundUnder25TLoads.xlsx",
          "application/octet-stream"
        );
      } catch (e) {
        console.log("runReport : Error: ", e);
        this.$notify({
          group: "global",
          title: "Error",
          text: "There was a problem running the report, please re-try.",
          type: "error",
        });
      } finally {
        this.$root.hideProcessing();
      }
    },

    initDates() {
      this.fromDate = dayjs()
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      this.toDate = dayjs().format("YYYY-MM-DD");
    },
  },
};
</script>
